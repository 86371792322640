@import 'theme';

.product-thumbnail {
    display: block;

    a {
        text-decoration: none;
    }
    
    p {
        color: #000000;
        margin-bottom: 10px;
    }

    img {
        border-radius: 8px;
        margin-bottom: 20px;
        width: 300px;
    }

    &__price {
        color: $orange;
    }
}

@include media-breakpoint-down(sm) {
    .product-thumbnail {
        width: 100%;
        text-align: center;
    }
}