@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

$dark-blue: #002C39;
$orange: #CD145A;
$grey: #6D6D6D;
$light-blue: #f6f5ff;

body {
    font-family: "Nunito Sans", sans-serif;
}

.body-spacer {
    margin-top: 97px;
    display: inline-block;
    width: 100%;

    @include media-breakpoint-down(sm) {
        margin-top: 87px;
    }
}

.primary-button {
    background-color: $orange;
    color: #FFFFFF;
    padding: 20px;
    border: none;
    border-radius: 999px;
    font-size: 24px;
    font-size: bold;
}

.color-grey {
    color: $grey;
}