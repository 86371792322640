@import 'theme';

.footer {
    background-color: black;
    color: white;

    ul {
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;

        li {
            padding: 5px 0;
            a {
                
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }

    p {
        margin: 0;
    }
}
