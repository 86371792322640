@import 'theme';

.nav-bar {
    padding: 20px;
    background-color: $dark-blue;
    color: $orange;
    position: fixed;
    top: 0;
    width: 100%;

    &__logo{
        height: 50px;
        margin-top: 4px;
    }

    ul {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        display: flex;
        margin: 0;
        list-style: none;
        width: 100%;

        li {
            padding: 20px;

            a {
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }

    button {
        padding-top: 15px;
        border: none;
        background: inherit;
        color: inherit;
    }

    &__nav-actions {
        &__menu {
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;

        &__items {
            color: #FFFFFF;
            border-radius: 8px;
            border: 1px $orange solid;
            background-color: $dark-blue;
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: absolute;
            top: 60px;
            padding: 20px;

            a {
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }


}
