@import 'theme';

.product-detail {
    &__details {
        display: flex;
        flex-direction: row;
        gap: 16px;

        &__attributes {
            margin-top: 48px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 20px;
            
            &__icons {
                display: flex;
                flex-direction: row;
                gap: 24px;
                color: $dark-blue;

                span {
                    margin-left: 10px;
                    color: black;
                    font-size: 24px;
                }
            }

            &__price {
                font-weight: bolder;
                font-size: 32px;
            }

            a {
                text-align: center;
                margin: 0 auto;
                width: 200px;
            }
        }

        img {
            width: 50%;
        }

        a {
            text-decoration: none;
        }
    }

    &__alt {
        background-color: $light-blue;
        text-align: center;
        
        li {
            list-style-type: none;
        }
    }

    &__video {
        iframe {
            width:60%;
            height:400px;
        }

        fieldset, legend {
            margin:0;
            padding:0;
            width:100%;
            height:100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .product-detail {
        &__details {
            flex-direction: column;
            gap: 8px;

            &__attributes {
                margin-top: 0;

                &__icons {
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 4px;

                    div {
                        width: 48%;
                    }
                }
            }

            img {
                width: 100%;
            }
        }

        &__video {
            iframe {
                width:100%;
                height:250px;
            }
        }
    }
}