@import 'theme';

.search {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-color: $dark-blue;
    top: 104px;

    @include media-breakpoint-down(sm) {
        top: 94px;
    }

    &__container {
        padding: 0 20px 20px 20px;
        display: flex;
        gap: 8px;

        form {
            width: 100%;
            display: flex;
            gap: 8px;
        }

        input {
            width: 100%;
            border-radius: 8px;
        }
    }

    &__search-button {
        background-color: $orange;
        color: #FFFFFF;
        border: none;
        border-radius: 8px;
    }

    &__close {
        padding-top: 15px;
        border: none;
        background: inherit;
        color: $orange;
    }
}