@import 'theme';

.paragraph-1 {
    font-size: 18px;
    margin-bottom: 20px;

    &__justify {
        text-align: justify;
    }
}

.heading-2 {
    font-size: 56px;
    line-height: 58px;
    font-weight: bolder;
    color: $orange;
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
        font-size: 30px;
    }
}

.heading-3 {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
        font-size: 20px;
    }
}

.heading-4 {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
        font-size: 15px;
    }
}

