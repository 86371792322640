@import 'theme';

.contact-us {
    &__block {
        display: flex;
        gap: 40px;

        div {
            width: 50%;

            img {
                width: 100%;
            }
        }

        a {
            color: #000000;
        }

        &__reverse {
            flex-direction: row-reverse;
        }
    }
}

@include media-breakpoint-down(md) {
    .contact-us {
        &__block {
            flex-direction: column;
        }

        div {
            width: 100%;
        }
    }
}