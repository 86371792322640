@import 'typography';
@import 'theme';

.home {
    &__featured-products {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__banner {
        width: 100%;
    }

    &__alt {
        background-color: $light-blue;

        &__block {
            display: flex;
            gap: 20px;

            div {
                width: 50%;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .home {
        &__alt {
            &__block {
                flex-direction: column;
                gap: 20px;

                div {
                    width: 100%;
                }
            }
        }
    }
}